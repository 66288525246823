import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider,
  useLocation,
} from 'react-router-dom'
import { createRoot } from 'react-dom/client'
import { FullStory, init as initFullStory } from '@fullstory/browser'
import { isAxiosResponseError } from '@tovala/browser-apis-core'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import * as Sentry from '@sentry/react'
import { fullStoryIntegration } from '@sentry/fullstory'
import { setupAPI as setupCombinedAPI } from '@tovala/browser-apis-combinedapi'
import { StrictMode } from 'react'

import { getCookie } from 'utils/storage'
import { getEnvVar } from 'utils/env'

import './index.css'
import 'apiRequests'
import { AuthProvider, useAuth } from 'contexts/auth'
import App from './App'
import LoginPage from 'components/auth/LoginPage'
import LogoutPage from 'components/auth/LogoutPage'
import UncaughtErrorPage from 'components/UncaughtErrorPage'
import OvensPage from 'components/ovens/OvensPage'
import OvenPage from 'components/ovens/OvenPage'
import JobsPage from 'components/ovens/JobsPage'
import QRCodesPage from 'components/ovens/QRCodesPage'

initFullStory({ orgId: getEnvVar('FULLSTORY_ORG_ID') })

Sentry.init({
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
  ],
  dsn: getEnvVar('SENTRY_DSN'),
  environment: getEnvVar('APP_ENV'),
  integrations: [
    Sentry.browserTracingIntegration(),
    fullStoryIntegration(getEnvVar('SENTRY_ORG'), { client: FullStory }),
  ],
  tracesSampleRate: 1.0,
})

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        const queryError = error instanceof Error ? error : null
        const statusCode = isAxiosResponseError(queryError)
          ? queryError.response?.status
          : undefined

        // We don't want to retry an API request on 4xx responses since they'll just keep failing.
        return (
          failureCount < 3 &&
          (!statusCode || statusCode < 400 || statusCode >= 500)
        )
      },
    },
  },
})

setupCombinedAPI({
  appID: getEnvVar('APP_NAME'),
  apiType: 'prod', // Yes, this is rude, but we always want to authenticate against production capi
  // getEnvVar('APP_ENV') === 'production' ? 'prod' : 'dev',
  getBearerToken: () => getCookie('JWT_TOKEN'),
})

const router = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      {
        path: 'login',
        element: <LoginPage />,
      },
      {
        element: <AuthenticatedRoute />,
        children: [
          { index: true, element: <OvensPage /> },
          { path: 'ovens/:ovenId', element: <OvenPage /> },
          { path: 'logout', element: <LogoutPage /> },
          { path: 'jobs', element: <JobsPage /> },
          { path: 'qrCodes', element: <QRCodesPage /> },
        ],
      },
    ],
  },
])

const root = createRoot(document.getElementById('root') as HTMLDivElement)
root.render(
  <StrictMode>
    <Sentry.ErrorBoundary fallback={<UncaughtErrorPage />}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <RouterProvider router={router} />
        </AuthProvider>

        <ReactQueryDevtools />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </StrictMode>,
)

function AuthenticatedRoute() {
  const { isLoggedIn } = useAuth()
  const location = useLocation()

  if (!isLoggedIn) {
    const redirect =
      location.pathname === '' ||
      location.pathname === '/login' ||
      location.pathname === '/logout'
        ? ''
        : `?redirect=${location.pathname}`

    return <Navigate replace to={`/login${redirect}`} />
  }

  return <Outlet />
}
