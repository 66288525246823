import { useEffect, useRef, useState } from 'react'

export type WebsocketHookOptions<T> = {
  enabled?: boolean
  onConnectData?: () => T
  onDisconnectData?: () => T
  clearDataOnConnect?: boolean
}

function useWebsocket<T>(
  url: string,
  parser: (data: string) => T[],
  options?: WebsocketHookOptions<T>,
) {
  const [data, setData] = useState<T[]>([])
  const [reconnect, setReconnect] = useState(true)
  const [connected, setConnected] = useState(false)
  const ws = useRef<WebSocket | null>(null)

  useEffect(() => {
    function connect() {
      if (!options?.enabled || !reconnect) {
        return
      }

      if (ws.current) {
        return
      }

      ws.current = new WebSocket(url)

      ws.current.onmessage = (event) => {
        try {
          const parsedData = parser(event.data)
          setData((prevData) => [...(prevData ?? []), ...parsedData])
        } catch (error) {
          console.error('Failed to parse data:', error)
        }
      }

      ws.current.onopen = () => {
        if (options?.clearDataOnConnect) {
          setData([])
        }
        if (options?.onConnectData) {
          setData((prevData) => [...(prevData ?? []), options.onConnectData!()])
        }
        setConnected(true)
      }

      ws.current.onclose = () => {
        if (options?.onDisconnectData) {
          setData((prevData) => [
            ...(prevData ?? []),
            options.onDisconnectData!(),
          ])
        }
        setConnected(false)
        setTimeout(connect, 1000)
        ws.current = null
      }
    }

    connect()

    return () => {
      if (!ws.current) {
        return
      }

      setConnected(false)

      if (ws.current.readyState === WebSocket.OPEN) {
        ws.current.close()
        ws.current = null
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, parser, reconnect, options?.clearDataOnConnect])

  return {
    data,
    setData,
    setReconnect,
    connected,
  }
}

export default useWebsocket
