import { Checkbox, Input, Modal, ModalHeader } from '@tovala/component-library'
import { clsx } from 'clsx'
import {
  sendOvenCommand,
  ovenCommands,
  OvenCommands,
  OvenCommandWithProps,
  OvenCommandParams,
  OvenCommandParamsTypes,
  StringParams,
  BooleanParams,
  ChoiceParams,
} from 'utils/commands'

import { useState } from 'react'

const Button = ({
  children,
  styles,
  onClick,
}: {
  children: React.ReactNode
  styles?: string
  onClick: () => void
}) => (
  <button
    className={`rounded-lg bg-orange-1 py-1 px-2 opacity-90 hover:opacity-100 ${styles}`}
    onClick={onClick}
  >
    {children}
  </button>
)

const OvenCommandsExecutor = ({ ovenId }: { ovenId: string }) => {
  const [cmd, setCmd] = useState<OvenCommands | null>(null)
  const [args, setArgs] = useState<Record<string, string | number | boolean>>(
    {},
  )

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)

  const [confirmingUnlock, setConfirmingUnlock] = useState(false)
  const [unlocked, setUnlocked] = useState(false)

  const unlock = () => {
    sendOvenCommand(ovenId, 'unlock')
    setConfirmingUnlock(false)
    setUnlocked(true)
  }

  const openPopup = (command: OvenCommands) => {
    setArgs({})
    setError(null)

    const commandArgs =
      OvenCommandParams[command as keyof typeof OvenCommandParams]
    Object.entries(commandArgs).forEach(([arg, desc]) => {
      if (typeof desc === 'object' && 'default' in desc) {
        setArgs((prev) => ({
          ...prev,
          [arg]: desc.default as string,
        }))
      }
    })

    setCmd(command as OvenCommands)
  }

  const PickList = ({ arg, param }: { arg: string; param: StringParams }) => {
    return (
      <select
        className="border border-grey-3 rounded-lg px-2 py-1"
        id={arg}
        onChange={(e) => {
          setArgs((prev) => ({
            ...prev,
            [arg]: e.target.value,
          }))
        }}
        value={(args[arg] as string) || ''}
      >
        <option value="">Select an option</option>
        {Array.isArray(param.options)
          ? param.options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))
          : typeof param.options === 'object'
            ? Object.entries(param.options).map(
                ([key, value]: [string, string | number]) => (
                  <option key={key} value={value}>
                    {key}
                  </option>
                ),
              )
            : null}
      </select>
    )
  }

  const StringInput = ({ arg }: { arg: string }) => (
    <Input
      id={arg}
      onChange={(e) => {
        setArgs((prev) => ({
          ...prev,
          [arg]: e.target.value,
        }))
      }}
      value={(args[arg] as string) || ''}
    />
  )

  const NumberInput = ({ arg }: { arg: string }) => (
    <Input
      id={arg}
      onChange={(e) => {
        setArgs((prev) => ({
          ...prev,
          [arg]: Number(e.target.value),
        }))
      }}
      value={(args[arg] as number) || 0}
    />
  )

  const BooleanInput = ({
    arg,
    param,
  }: {
    arg: string
    param: BooleanParams
  }) => (
    <div className="flex items-center py-2 gap-2">
      <Checkbox
        checked={args[arg] as boolean}
        id={arg}
        name={arg}
        onChange={(e) => {
          setArgs((prev) => ({
            ...prev,
            [arg]: e.target.checked,
          }))
        }}
        type="checkbox"
      />

      <label htmlFor={arg}>{param.prettyName}</label>
    </div>
  )

  const RadioInput = ({ arg, param }: { arg: string; param: ChoiceParams }) => (
    <div className="flex items-center py-2 gap-2 flex justify-around">
      {Object.entries(param.options).map(([label, value]: [string, string]) => {
        const id = `${arg}-${value}`
        return (
          <div key={value}>
            <input
              checked={args[arg] === value}
              className="mx-2 font-lg cursor-pointer"
              id={id}
              name={arg}
              onChange={(e) =>
                setArgs((prev) => ({ ...prev, [arg]: e.target.value }))
              }
              type="radio"
              value={value}
            />
            <label className="cursor-pointer" htmlFor={id}>
              {label}
            </label>
          </div>
        )
      })}
    </div>
  )

  const ParamInput = ({
    name,
    param,
  }: {
    name: string
    param: OvenCommandParamsTypes
  }) => (
    <div key={name}>
      {param.type !== 'boolean' && (
        <label htmlFor={name}>{param.prettyName}</label>
      )}
      {param.type === 'string' &&
        (param.options ? (
          <PickList arg={name} param={param} />
        ) : (
          <StringInput arg={name} />
        ))}
      {param.type === 'number' && <NumberInput arg={name} />}
      {param.type === 'boolean' && <BooleanInput arg={name} param={param} />}
      {param.type === 'radio' && <RadioInput arg={name} param={param} />}
    </div>
  )

  const CommandModal = () => {
    const allParams = OvenCommandParams[cmd as keyof typeof OvenCommandParams]
    const params = Object.fromEntries(
      Object.entries(allParams).filter(([key]) => key !== 'prettyName'),
    ) as Record<string, OvenCommandParamsTypes>
    const prettyName = allParams.prettyName
    const sufficientFields = Object.entries(params)
      .filter(([key]) => key !== 'prettyName')
      .every(
        ([key, value]) =>
          (value as OvenCommandParamsTypes).type === 'boolean' ||
          args[key] !== undefined,
      )

    const submitCommand = async () => {
      if (loading) {
        return
      }

      if (!sufficientFields) {
        setError('Please fill all fields')
        return
      }

      setError(null)
      setLoading(true)

      try {
        await sendOvenCommand(
          ovenId,
          cmd as OvenCommandWithProps,
          // This is safe because we are checking if all fields are filled
          // And the backend will validate everything as well
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          args as any,
        )
      } catch (e) {
        if (e instanceof Error) {
          setError(e.message)
        } else {
          setError('An error occurred')
        }
        setLoading(false)
        return
      }

      setLoading(false)

      setCmd(null)
      setArgs({})
    }

    return (
      <Modal
        onCloseModal={() => {
          setCmd(null)
          setArgs({})
          setError(null)
          setLoading(false)
        }}
      >
        <ModalHeader
          onClickClose={() => {
            setCmd(null)
            setArgs({})
            setError(null)
            setLoading(false)
          }}
        >
          <span className="text-xl font-bold">{prettyName}</span>
        </ModalHeader>

        <div className="m-4 min-w-60">
          <div className="flex flex-col gap-4">
            <div>
              {Object.entries(params).map(([key, param]) => (
                <ParamInput key={key} name={key} param={param} />
              ))}
            </div>

            {error && <div className="text-red text-sm">{error}</div>}

            <div className="flex flex-row justify-end">
              <button
                className={clsx('text-white rounded-lg p-2', {
                  // All args are filled
                  'bg-orange-1': sufficientFields,
                  'bg-gray-400': !sufficientFields,
                })}
                disabled={loading}
                onClick={submitCommand}
              >
                Send Command
              </button>
            </div>
          </div>
        </div>
      </Modal>
    )
  }

  const ConfirmModal = () => (
    <Modal onCloseModal={() => setConfirmingUnlock(false)}>
      <ModalHeader onClickClose={() => setConfirmingUnlock(false)}>
        <span className="text-xl font-bold">Unlock Device</span>
      </ModalHeader>

      <div className="m-5 text-md max-w-md">
        <p className="m-2">Are you sure you want to unlock this device?</p>
        <p className="m-2">
          By unlocking this device, you are enabling debugging commands, which
          can be dangerous if used incorrectly.
        </p>

        <p className="text-center font-bold text-xl m-4">Be responsible!</p>

        <div className="flex items-center justify-center gap-4">
          <Button onClick={() => unlock()} styles="bg-red-903 text-red-901">
            I solemnly swear that I am up to no good!
          </Button>
          <Button
            onClick={() => setConfirmingUnlock(false)}
            styles="text-white"
          >
            Get me outta here!
          </Button>
        </div>
      </div>
    </Modal>
  )

  return (
    <div className="relative rounded-lg shadow-lg overflow-clip p-2 bg-slate-50 border border-gray-200 p-2">
      <div className="flex flex-wrap gap-2 lg:gap-1 justify-evenly">
        <Button
          onClick={() => setConfirmingUnlock(true)}
          styles="relative bg-red-906 border-red-905 z-10 text-white"
        >
          Unlock
        </Button>
        {ovenCommands
          .filter((c) => c !== 'unlock')
          .map((command, index) => (
            <Button
              key={index}
              onClick={() => openPopup(command as OvenCommands)}
              styles="text-white rounded-lg p-2 bg-orange-1"
            >
              {
                OvenCommandParams[command as keyof typeof OvenCommandParams]
                  .prettyName
              }
            </Button>
          ))}
        {!unlocked && (
          <div className="absolute top-0 left-0 w-full h-full opacity-80 bg-gray-100 cursor-not-allowed z-0"></div>
        )}
      </div>
      {cmd && <CommandModal />}
      {confirmingUnlock && <ConfirmModal />}
    </div>
  )
}

export default OvenCommandsExecutor
