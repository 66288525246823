import { ReactNode, useState } from 'react'
import { Tooltip, Checkbox } from '@tovala/component-library'
import {
  CloudOutlineIcon,
  HardwareChipOutlineIcon,
  ExpandIcon,
} from 'components/common/Icons'

import PaginationTableFooter from 'components/common/PaginationTableFooter'
import { OvenData, ThingNames, useOvenDetails } from 'utils/oatsApi'
import ConnectionState from './ConnectionState'

import EditThingsModal from './EditThingsModal'
import Link from 'components/common/Link'

type Column = {
  style?: string
  title?: string | ReactNode
  tooltip?: boolean
  filter?: ReactNode
}
type Columns = { [key: string]: Column }

const Loading = () => (
  <div className="absolute inset-0 bg-white bg-opacity-90 flex items-center justify-center">
    <svg
      aria-hidden="true"
      className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
      fill="none"
      viewBox="0 0 100 101"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
        fill="currentColor"
      />
      <path
        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
        fill="currentFill"
      />
    </svg>

    <div className="text-center text-gray-500 p-4">Loading...</div>
  </div>
)

const OvenList = ({
  ovens,
  total,
  page,
  limit,
  setPage,
  setLimit,
  setSearch,
  isLoading,
  error,
  isPreviousData,
  givenNames,
  invalidate,
}: {
  ovens: OvenData[]
  total: number
  page: number
  limit: number
  setPage: (page: ((page: number) => number) | number) => void
  setLimit: (limit: ((limit: number) => number) | number) => void
  setSearch: (search: string) => void
  isLoading: boolean
  error?: Error
  isPreviousData: boolean
  givenNames?: ThingNames
  invalidate: () => void
}) => {
  const [selected, setSelected] = useState<OvenData[]>([])
  const [editOpen, setEditOpen] = useState<boolean>(false)
  const [expanded, setExpanded] = useState<string[]>([])

  const Expander = ({ oven }: { oven: OvenData }) => {
    return (
      <button
        className={`align-middle btn btn-primary w-4 h-4 pr-0 transition-transform ${expanded.includes(oven.thingName) ? 'rotate-90' : 'rotate-0'}`}
        onClick={() => {
          setExpanded((prev) =>
            prev.includes(oven.thingName)
              ? prev.filter((item) => item !== oven.thingName)
              : [...prev, oven.thingName],
          )
        }}
      >
        <ExpandIcon />
      </button>
    )
  }

  const FilterInput = () => (
    <input
      className="p-1 rounded-sm border-1 h-7 text-gray-500"
      placeholder="..."
    />
  )

  const columns: Columns = {
    select: { style: 'w-10' },
    expand: { style: 'w-6' },
    thingName: {
      title: 'Name',
      filter: <FilterInput />,
      style: 'max-w-[10em] truncate',
    },
    serialNumber: {
      title: 'Serial',
      filter: <FilterInput />,
      style: 'lg:w-40 sm:w-0 md:w-auto md:text-right md:w-40 md:pr-2 sm:pr-0',
    },
    deviceModel: { title: 'Model', style: 'w-20 md:w-0' },
    firmwareVersion: {
      title: 'Firmware',
      filter: <FilterInput />,
      style: 'md:w-0 xl:w-40',
    },
    groups: { title: 'Groups', style: '2xl:w-0' },
    ovenState: { title: 'State', style: '2xl:w-30 xl:w-24 lg:w-0' },
    connection: { style: 'w-6' },
    actions: { style: 'w-16' },
  }

  const OvenRow = ({ index, oven }: { index: number; oven: OvenData }) => {
    const { data: fetchedDetails } = useOvenDetails(oven.thingName)
    const thingName = oven.thingName
    const givenName = givenNames && givenNames[thingName]

    const OvenDetails = ({ oven }: { oven: OvenData }) => {
      const SearchLink = ({
        text,
        classNames,
        label,
      }: {
        text: string
        label?: string
        classNames?: string
      }) => (
        <a
          className={`text-blue-400 cursor-pointer hover:underline ${classNames}`}
          onClick={() => setSearch(text)}
        >
          {label || text}
        </a>
      )

      const None = () => <span className="text-gray-400">none</span>
      const details = {
        'Given Name': givenName || <None />,
        'Serial Number': oven.attributes?.serialNumber,
        'Device ID': oven.thingName,
        'Device Model': (
          <SearchLink text={oven.attributes?.deviceModel || ''} />
        ),
        Owner: oven.userid ? (
          <SearchLink
            label={`${oven.userid} / ${oven.email}` || ''}
            text={oven.email!}
          />
        ) : (
          <None />
        ),
        'Oven State': oven.state,
        'Firmware Version': <SearchLink text={oven.firmwareVersion || ''} />,
        'Config Group': (
          <SearchLink
            label={oven.attributes?.configGroup || ''}
            text={
              oven.attributes?.configGroup
                ? '+group:' + oven.attributes?.configGroup
                : ''
            }
          />
        ),
        'Thing Groups': oven.thingGroupNames?.map((thingGroup) => (
          <span key={thingGroup} className="pr-2">
            <SearchLink
              label={thingGroup || ''}
              text={thingGroup ? '+group:' + thingGroup : ''}
            />
          </span>
        )),
        Connection: (
          <ConnectionState connection={oven.connectivity} text={true} />
        ),
      }
      const widths: { [key: string]: string } = {
        'Config Group': 'w-[60%]',
        'Thing Groups': 'w-[60%]',
        Connection: 'w-[60%]',
      }
      return (
        <div className="border rounded-md p-2">
          <div className="align-middle gap-x-[1em] gap-y-[.25em] flex flex-wrap">
            {Object.entries(details).map(([key, value]) => (
              <div
                key={key}
                className={`truncate flex gap-2 min-w-[16em] ${widths[key] || 'w-[30%]'} whitespace-pre lg:w-[100%]`}
              >
                <div className="font-semibold">{key + ':'}</div>
                <div>{value}</div>
              </div>
            ))}
          </div>
        </div>
      )
    }
    const ovenDetails = fetchedDetails || oven

    const bg = index % 2 === 0 ? ' bg-gray-50' : ' bg-white'

    const Groups = () => {
      const o = ovenDetails
      const groups = [
        o.attributes?.configGroup || '',
        ...(o.thingGroupNames || []),
      ]
        .filter((e) => e !== '')
        .sort((a, b) => a.length - b.length)
      const ph = groups.length === 0 ? '<none>' : groups[0]
      const ymas =
        groups.length > 1 ? (
          <span className="text-gray-400 text-sm pl-2">
            +{groups.length - 1}
          </span>
        ) : (
          <></>
        )

      return (
        <Tooltip
          trigger={
            <span>
              {ph}
              {ymas}
            </span>
          }
        >
          {groups.join(', ')}
        </Tooltip>
      )
    }

    return (
      <tbody className={bg}>
        <tr key={index} className="h-10">
          <td key="select">
            <div className="w-2 px-2">
              <Checkbox
                checked={
                  selected.find((e) => e.thingName === ovenDetails.thingName) &&
                  true
                }
                name={ovenDetails.thingName}
                onChange={() => {
                  setSelected((prev) =>
                    prev.find((o) => o.thingName === ovenDetails.thingName)
                      ? prev.filter(
                          (o) => o.thingName !== ovenDetails.thingName,
                        )
                      : [...prev, ovenDetails],
                  )
                }}
              />
            </div>
          </td>
          <td key="expand">
            <Expander oven={ovenDetails} />
          </td>
          <td
            key="thingName"
            className="truncate cursor-pointer hover:underline"
          >
            <Link
              className="truncate text-blue-400"
              href={'/ovens/' + thingName}
            >
              {givenName ? (
                <Tooltip trigger={<span>{givenName}</span>}>
                  {thingName}
                </Tooltip>
              ) : (
                thingName
              )}
            </Link>
          </td>
          <td
            key="serialNumber"
            className={`truncate ${columns.serialNumber.style}`}
          >
            {ovenDetails.attributes?.serialNumber}
          </td>
          <td
            key="deviceModel"
            className={`truncate ${columns.deviceModel.style}`}
          >
            {ovenDetails.attributes?.deviceModel}
          </td>
          <td
            key="firmwareVersion"
            className={`${columns.firmwareVersion.style}`}
          >
            <div className="truncate">
              <Tooltip trigger={<span>{ovenDetails.firmwareVersion}</span>}>
                {ovenDetails.firmwareVersion}
              </Tooltip>
            </div>
          </td>
          <td key="groups" className={`truncate ${columns.groups.style}`}>
            <Groups />
          </td>
          <td key="ovenState" className={`truncate ${columns.ovenState.style}`}>
            <Tooltip trigger={<span>{ovenDetails.state}</span>}>
              {ovenDetails.state}
            </Tooltip>
          </td>
          <td key="connection">
            <div className="w-6 flex mx-auto">
              <div className="flex-1">
                <ConnectionState connection={ovenDetails.connectivity} />
              </div>
            </div>
          </td>
          <td key="actions">
            <div className="flex justify-center items-center gap-2 px-2">
              <a
                className="text-blue-400 w-6"
                href={ovenDetails.aws_thing_url}
                rel="noreferrer"
                target="_blank"
              >
                <HardwareChipOutlineIcon />
              </a>
              <a
                className="text-blue-400 w-6"
                href={ovenDetails.aws_cloudwatch_url}
                rel="noreferrer"
                target="_blank"
              >
                <CloudOutlineIcon />
              </a>
            </div>
          </td>
        </tr>
        {expanded.includes(oven.thingName) && (
          <tr>
            <td className="p-2" colSpan={Object.keys(columns).length}>
              <OvenDetails oven={ovenDetails} />
            </td>
          </tr>
        )}
      </tbody>
    )
  }

  const Filters = () => <></>
  /*
  (
    <tr>
      {Object.entries(columns).map(([key, column]: ([key: string, column: Column])) => (
        <th key={key} className={`text-sm pb-2 text-left ${column.style || ""}`}>
          {column.filter}
        </th>
      ))}
    </tr>
  )
    */

  return (
    <div className="relative overflow-x-hidden rounded-lg shadow-lg mb-4">
      {isLoading && <Loading />}

      <div className="flex gap-2 ml-2 cursor-pointer select-none items-center">
        {selected.length > 0 ? (
          <span
            className="underline"
            onClick={() => {
              setSelected([])
            }}
          >
            Unselect All
          </span>
        ) : (
          <span
            className="underline"
            onClick={() => {
              setSelected(ovens)
            }}
          >
            Select All
          </span>
        )}
        {selected.length > 0 && (
          <span
            className="underline"
            onClick={() => {
              setEditOpen(true)
            }}
          >
            Edit Groups
          </span>
        )}
      </div>

      <div className="overflow-clip rounded-lg">
        <div className="overflow-x-auto">
          <table className="table-fixed w-full">
            <thead className="bg-orange-1 text-white">
              <tr>
                {Object.entries(columns).map(
                  ([key, column]: [key: string, column: Column]) => (
                    <th
                      key={key}
                      className={`p-0 py-1 overflow-hidden text-md text-left ${column.style || ''}`}
                    >
                      {column.title}
                    </th>
                  ),
                )}
              </tr>
              <Filters />
            </thead>
            {Array.from(ovens.entries()).map(
              ([index, oven]: [index: number, oven: OvenData]) => (
                <OvenRow key={index} index={index} oven={oven} />
              ),
            )}
          </table>
        </div>

        <PaginationTableFooter
          isLoading={isPreviousData}
          onChangePage={setPage}
          onChangeRowsPerPage={setLimit}
          page={page}
          rowsPerPage={limit}
          total={total}
          totalPages={Math.ceil(total / limit)}
        >
          {error && (
            <div className="text-center text-red-500 p-4 flex-1">
              Error: {(error as Error)?.message ?? error}
            </div>
          )}
        </PaginationTableFooter>

        {selected.length > 0 && editOpen && (
          <EditThingsModal
            invalidate={() => {
              setSelected([])
              invalidate()
            }}
            selected={selected}
            setEditOpen={setEditOpen}
          />
        )}
      </div>
    </div>
  )
}

export default OvenList
