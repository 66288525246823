import { getEnvVar } from 'utils/env'
import { useQuery } from '@tanstack/react-query'

export type OvenLookupData = {
  [key: string]: string
}

export type ThingHistory = {
  deviceID: string
  favorited: boolean
  name: string
  user: string
  viewedAtMs: string
}

export type ThingNames = {
  [deviceID: string]: string
}

export type OvenData = {
  thingName: string
  thingId?: string
  attributes?: {
    configGroup: string
    deviceModel: string
    serialNumber?: string
  }
  aws_cloudwatch_url?: string
  aws_thing_url?: string
  connectivity?: {
    connected: boolean
    disconnectReason?: string
    timestamp: number
  }
  thingGroupNames?: string[]
  state?: string
  favorited?: boolean
  givenName?: string
  viewedAtMs?: string
  shadow?: string
  rssi?: string
  ipAddress?: string
  configVersion?: string
  firmwareVersion?: string
  userid?: number
  email?: string
}

export type GenericResponse<T> = {
  data: T
  message: string
  status: boolean
}

export type ThingGroupData = {
  group_arn: string
  group_id: null
  group_name: string
  total_things: 'Unknown'
  version: null
}

export type ImageData = {
  file_name: string
  file_size: number
  last_modified: string
}

export type JobProcessDetails = {
  numberOfCanceledThings: number
  numberOfFailedThings: number
  numberOfInProgressThings: number
  numberOfQueuedThings: number
  numberOfRejectedThings: number
  numberOfRemovedThings: number
  numberOfSucceededThings: number
  numberOfTimedOutThings: number
}

export type JobData = {
  created_at: string
  is_concurrent: boolean
  job_arn: string
  job_executions_rollout_config: {
    maximumPerMinute: number
  }
  job_id: string
  job_process_details: JobProcessDetails
  last_updated_at: string
  presigned_url_config: {
    expiresInSec: number
    roleArn: string
  }
  scheduling_config: unknown
  status: JobStatus
  target_selection: string
  targets: string[]
  timeout_config: unknown
}

export type APISearchResult = {
  ovens: OvenData[]
  total: number
}

export const jobStatuses = [
  'IN_PROGRESS',
  'CANCELED',
  'COMPLETED',
  'DELETION_IN_PROGRESS',
  'SCHEDULED',
] as const

export type JobStatus = (typeof jobStatuses)[number]
export type TargetSelection = 'SNAPSHOT' | 'CONTINUOUS'

export type JobDataLight = {
  created_at: string
  job_arn: string
  job_id: string
  last_updated_at: string
  status: JobStatus
  target_selection: TargetSelection
}

export type JobDataForThing = {
  job_execution_summary: {
    executionNumber: 1
    lastUpdatedAt: string
    queuedAt: string
    retryAttempt: number
    startedAt: string
    status: JobStatus
  }
  job_id: string
}

export type JSONArray = JSONValue[]
export type JSONObject = { [key: string]: JSONValue }
export type JSONPrimitives = string | number | boolean | null
export type JSONValue = JSONPrimitives | JSONArray | JSONObject

export type OvenEvent = {
  deviceID: string
  eventKey: string
  eventTimeMs: number
  eventType: string
  payload: null | JSONObject
  receiveTimeMs: number
  routineHash?: string
  cookCycleID?: string
  ttl: number
}

export function getAuthToken() {
  const cookieStr = document.cookie
  const cookies = cookieStr
    .split(';')
    .map((cookie) => ({
      [cookie.split('=')[0].trim()]: cookie.split('=')?.[1],
    }))
    .reduce((acc, curr) => ({ ...acc, ...curr }), {})

  if (!Object.keys(cookies).length) {
    console.error('No cookies found')
    throw new Error('No cookies found')
  }

  const cookieKey = getEnvVar('JWT_COOKIE_NAME')
  if (!cookies[cookieKey]) {
    throw new Error('No auth token found')
  }

  return cookies[cookieKey]
}

export async function fetchThingGroups(
  bearerToken: string,
  page: number = 1,
  page_size = 250,
): Promise<ThingGroupData[]> {
  // TODO: Remove hardcoded URL
  const url2 = `${getEnvVar('OATS_API_URL')}/api/ota/groups?page=${
    page
  }&per_page=${page_size}`
  const resp = await fetch(url2, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearerToken,
    },
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json2 = (await resp.json()) as GenericResponse<ThingGroupData[]>

  if (!json2.status) {
    console.error('Failed to search', json2.message)
    throw new Error(json2.message)
  }

  return json2.data
}

export function useThingGroups() {
  return useQuery(
    ['thing-groups'],
    async () => {
      return await fetchThingGroups(getAuthToken())
    },
    queryOpts,
  )
}

export async function fetchImages(
  bearerToken: string,
  page: number = 0,
  page_size = 250,
): Promise<ImageData[]> {
  const url = `${getEnvVar('OATS_API_URL')}/api/ota/images?page=${
    page
  }&per_page=${page_size}`
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearerToken,
    },
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json = (await resp.json()) as GenericResponse<ImageData[]>

  if (!json.status) {
    console.error('Failed to search', json.message)
    throw new Error(json.message)
  }

  return json.data
}

export async function prePublishOTAAffected(
  bearerToken: string,
  devices: string[],
  groups: string[],
) {
  if (!devices.length && !groups.length) {
    console.error('No devices or groups provided')
    throw new Error('No devices or groups provided')
  }

  const url = `${getEnvVar('OATS_API_URL')}/api/ota/jobs/affected`
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearerToken,
    },
    body: JSON.stringify({ deviceID: devices, deviceGroup: groups }),
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json = (await resp.json()) as GenericResponse<number>
  return json.data
}

export async function publishOTA(
  bearerToken: string,
  devices: string[],
  groups: string[],
  image: string,
  customId: string | null,
) {
  if (!devices.length && !groups.length) {
    console.error('No devices or groups provided')
    throw new Error('No devices or groups provided')
  }

  const url = `${getEnvVar('OATS_API_URL')}/api/ota/jobs/start/${image}`
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearerToken,
    },
    body: JSON.stringify({
      deviceID: devices,
      deviceGroup: groups,
      jobID: customId || undefined,
    }),
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json = (await resp.json()) as GenericResponse<string>
  return json.data
}

export async function addGroupToThings(
  bearerToken: string,
  group: string,
  things: string[],
) {
  if (!things.length) {
    console.error('No things provided')
    throw new Error('No things provided')
  }

  const url = `${getEnvVar('OATS_API_URL')}/api/ota/groups/${group}/things`
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearerToken,
    },
    body: JSON.stringify({ things }),
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json = (await resp.json()) as GenericResponse<null>
  return json.data
}

export async function removeGroupFromThings(
  bearerToken: string,
  group: string,
  things: string[],
) {
  if (!things.length) {
    console.error('No things provided')
    throw new Error('No things provided')
  }

  const url = `${getEnvVar('OATS_API_URL')}/api/ota/groups/${group}/things`
  const resp = await fetch(url, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearerToken,
    },
    body: JSON.stringify({ things }),
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json = (await resp.json()) as GenericResponse<null>
  return json.data
}

export async function fetchInProgressJobs(
  bearerToken: string,
  page: number = 1,
  page_size = 250,
) {
  const url = `${getEnvVar('OATS_API_URL')}/api/ota/jobs?page=${
    page
  }&per_page=${page_size}`
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearerToken,
    },
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json = (await resp.json()) as GenericResponse<JobData[]>
  return json.data
}

export async function fetchJobHistory(
  bearerToken: string,
  page: number = 1,
  page_size = 250,
  status: JobStatus = 'COMPLETED',
) {
  const url = `${getEnvVar('OATS_API_URL')}/api/ota/jobs/history?page=${
    page
  }&per_page=${page_size}&job_status=${status}`
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearerToken,
    },
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json = (await resp.json()) as GenericResponse<JobDataLight[]>
  return json.data
}

export async function fetchJobDetails(bearerToken: string, jobID: string) {
  const url = `${getEnvVar('OATS_API_URL')}/api/ota/jobs/id/${jobID}`
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearerToken,
    },
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json = (await resp.json()) as GenericResponse<JobData>
  return json.data
}

export async function getSearchCount(
  bearerToken: string,
  queryParams: string,
): Promise<number> {
  const url = `${getEnvVar('OATS_API_URL')}/api/search/count?${queryParams}`
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearerToken,
    },
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  console.log('Success', resp.status, resp.statusText)

  const json = (await resp.json()) as GenericResponse<number | undefined>

  console.log(json)

  if (!json.status) {
    console.error('Failed to search', json.message)
    return 0
  }

  const count = json.data!

  return count
}

export async function getSearchResults(
  bearerToken: string,
  queryParams: string,
): Promise<APISearchResult> {
  // TODO: Remove hardcoded URL
  const url2 = `${getEnvVar('OATS_API_URL')}/api/search?${queryParams}`
  const resp = await fetch(url2, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearerToken,
    },
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  console.log('Success', resp.status, resp.statusText)

  const json2 = (await resp.json()) as GenericResponse<APISearchResult>

  console.log(json2)

  return json2.data
}

/**
 * @throws No search data provided
 * @throws No authToken found
 * @throws Failed to fetch / search
 * @throws No results found
 */
export async function searchForThings(query: {
  key: string
  page: number
  limit: number
  stateFilter: string[]
}): Promise<APISearchResult> {
  const { key, page, limit, stateFilter } = query
  console.log('Searching', key, page, limit, stateFilter)

  const params = new URLSearchParams({ key })
  params.append('page', page.toString())
  params.append('per_page', limit.toString())
  params.append('state', stateFilter.join(','))

  const paramsStr = params.toString()
  console.log(paramsStr)

  const authToken = getAuthToken()
  if (!authToken) {
    console.error('No authToken found')
    throw new Error('No authToken found')
  }

  try {
    return await getSearchResults(authToken, paramsStr)
  } catch (e) {
    console.log('Error when searching: ', e)
    return {
      ovens: [] as OvenData[],
      total: 0,
    }
  }
}

/**
 * @throws No search data provided
 * @throws No authToken found
 * @throws Failed to fetch / search
 * @throws No results found
 */
export async function fetchThing(
  bearerToken: string,
  thingId: string,
): Promise<OvenData> {
  const url2 = `${getEnvVar('OATS_API_URL')}/api/oven/${thingId}`
  const resp = await fetch(url2, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + bearerToken,
    },
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  console.log('Success', resp.status, resp.statusText)

  const json2 = (await resp.json()) as GenericResponse<OvenData>

  console.log(json2)

  if (!json2.status) {
    console.error('Failed to search', json2.message)
    throw new Error(json2.message)
  }

  return json2.data
}

type UnparsedOvenEvent = OvenEvent & {
  eventTimeMs: string | number
  receiveTimeMs: string | number
  ttl: string | number
  payload?: string
}

function convertUnparsedOvenEvent(event: UnparsedOvenEvent) {
  if (event.payload) {
    try {
      event.payload = JSON.parse(event.payload)
    } catch (e) {
      console.error('Failed to parse payload', e)
    }
  }
  if (typeof event.eventTimeMs === 'string') {
    event.eventTimeMs = parseInt(event.eventTimeMs, 10)
  }
  if (typeof event.receiveTimeMs === 'string') {
    event.receiveTimeMs = parseInt(event.receiveTimeMs, 10)
  }
  if (typeof event.ttl === 'string') {
    event.ttl = parseInt(event.ttl, 10)
  }
  return event as OvenEvent
}

export async function getMostRecentThingEvents(
  thingId: string,
  page: number = 1,
  page_size = 250,
) {
  const url = `${getEnvVar('OATS_API_URL')}/api/oven/events/${thingId}?page=${
    page
  }&per_page=${page_size}`
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json = (await resp.json()) as GenericResponse<UnparsedOvenEvent[]>
  return json.data.map(convertUnparsedOvenEvent)
}

export type OatsOvenCommandBake = {
  add: string
}
export type OatsOvenCommandTimer = {
  add: string
}
export type OatsOvenCommandRoutine = {
  add: string
}
export type OatsOvenCommandCancel = undefined
export type OatsOvenCommandFlashAndBeep = undefined
export type OatsOvenCommandPartyRock = undefined
export type OatsOvenCommandLamp = {
  state: 'on' | 'off'
}
export type OatsOvenCommandDrain = {
  state: 'on' | 'off'
}
export type OatsOvenCommandConvection = {
  state: 'on' | 'off'
}
export type OatsOvenCommandCoolingFan = {
  state: 'on' | 'off'
}
export type OatsOvenCommandPushButton = {
  button: number
}
export type OatsOvenCommandScanBarcode = {
  barcode: string
}
export type OatsOvenCommandForceRestart = undefined
export type OatsOvenCommandKeyScanFlush = undefined
export type OatsOvenCommandResetChips = undefined
export type OatsOvenCommandRestart = {
  force: boolean
}
export type OatsOvenCommandIdentify = undefined
export type OatsOvenCommandGetState = {
  key: string
}

export type OatsOvenCommands = {
  bake: OatsOvenCommandBake
  timer: OatsOvenCommandTimer
  routine: OatsOvenCommandRoutine
  cancel: OatsOvenCommandCancel
  identify: OatsOvenCommandIdentify
  restart: OatsOvenCommandRestart
  lamp: OatsOvenCommandLamp
  drain: OatsOvenCommandDrain
  convection: OatsOvenCommandConvection
  coolingFan: OatsOvenCommandCoolingFan
  pushButton: OatsOvenCommandPushButton
  scanBarcode: OatsOvenCommandScanBarcode
  forceRestart: OatsOvenCommandForceRestart
  keyScanFlush: OatsOvenCommandKeyScanFlush
  resetChips: OatsOvenCommandResetChips
  getState: OatsOvenCommandGetState
}

type DefaultParams = {
  prettyName: string
}

type StringParams = {
  type: 'string'
  default?: string
  options?: string[]
} & DefaultParams

type NumberParams = {
  type: 'number'
  default?: number
  min?: number
  max?: number
} & DefaultParams

type BooleanParams = {
  type: 'boolean'
  default?: boolean
} & DefaultParams

export type OvenCommandParamsTypes = StringParams | NumberParams | BooleanParams

export const OvenCommandParams: {
  [K in keyof OatsOvenCommands]: Record<
    keyof OatsOvenCommands[K],
    OvenCommandParamsTypes
  > &
    DefaultParams
} = {
  bake: {
    prettyName: 'Bake',
    add: { prettyName: 'Add Time', type: 'string' },
  },
  timer: {
    prettyName: 'Timer',
    add: { prettyName: 'Add Time', type: 'string' },
  },
  routine: {
    prettyName: 'Routine',
    add: { prettyName: 'Add Time', type: 'string' },
  },
  cancel: {
    prettyName: 'Cancel',
  },
  identify: {
    prettyName: 'Flash and Beep',
  },
  restart: {
    prettyName: 'Restart',
    force: { prettyName: 'Force Restart', type: 'boolean' },
  },
  lamp: {
    prettyName: 'Lamp',
    state: { prettyName: 'Lamp State', type: 'string', options: ['on', 'off'] },
  },
  drain: {
    prettyName: 'Drain',
    state: {
      prettyName: 'Drain State',
      type: 'string',
      options: ['on', 'off'],
    },
  },
  convection: {
    prettyName: 'Convection',
    state: {
      prettyName: 'Convection State',
      type: 'string',
      options: ['on', 'off'],
    },
  },
  coolingFan: {
    prettyName: 'Cooling Fan',
    state: {
      prettyName: 'Cooling Fan State',
      type: 'string',
      options: ['on', 'off'],
    },
  },
  pushButton: {
    prettyName: 'Push Button',
    button: {
      prettyName: 'Button Number',
      type: 'number',
      min: 0,
      max: 255,
    },
  },
  scanBarcode: {
    prettyName: 'Scan Barcode',
    barcode: {
      prettyName: 'Barcode',
      type: 'string',
    },
  },
  forceRestart: {
    prettyName: 'Force Restart',
  },
  keyScanFlush: {
    prettyName: 'Key Scan Flush',
  },
  resetChips: {
    prettyName: 'Reset Chips',
  },
  getState: {
    prettyName: 'Get State',
    key: {
      prettyName: 'State Key',
      type: 'string',
    },
  },
}

export type OvenCommands = keyof OatsOvenCommands

export type OvenCommandEmpty = keyof OatsOvenCommands &
  {
    [K in keyof OatsOvenCommands]: OatsOvenCommands[K] extends undefined
      ? K
      : never
  }[keyof OatsOvenCommands]

export type OvenCommandWithProps = keyof OatsOvenCommands &
  {
    [K in keyof OatsOvenCommands]: OatsOvenCommands[K] extends undefined
      ? never
      : K
  }[keyof OatsOvenCommands]

export const ovenCommands = [
  'unlock',
  'bake',
  'timer',
  'routine',
  'cancel',
  'flashAndBeep',
  'partyRock',
  'lamp',
  'drain',
  'convection',
  'coolingFan',
  'pushButton',
  'scanBarcode',
  'forceRestart',
  'keyScanFlush',
  'resetChips',
  'getState',
] as OvenCommands[]

export async function sendOvenCommand<T extends OvenCommands>(
  ...args: T extends OvenCommandEmpty
    ? [thingId: string, command: T]
    : T extends OvenCommandWithProps
      ? [thingId: string, command: T, props: OatsOvenCommands[T]]
      : never
) {
  const [thingId, command, props] = args
  const url = `${getEnvVar('OATS_API_URL')}/api/oven/${thingId}`
  const body = {
    command,
    ...props,
  }

  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
    body: JSON.stringify(body),
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json = (await resp.json()) as GenericResponse<string>

  return json.data
}

export async function fetchJobsByThing(
  thingId: string,
  page: number,
  pageSize: number,
) {
  const url = `${getEnvVar('OATS_API_URL')}/api/ota/jobs/thing/${
    thingId
  }?page=${page}&per_page=${pageSize}`
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json = (await resp.json()) as GenericResponse<JobDataForThing[]>

  return json.data
}

export async function fetchHistory(page: number, pageSize: number) {
  const url = `${getEnvVar('OATS_API_URL')}/api/oven/history?page=${page}&per_page=${pageSize}`
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json = (await resp.json()) as GenericResponse<ThingHistory[]>

  return json.data
}

export async function fetchFavorites(
  page: number,
  pageSize: number,
  universal: boolean,
) {
  const url = `${getEnvVar('OATS_API_URL')}/api/oven/favorites?page=${page}&per_page=${pageSize}&universal=${universal}`
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json = (await resp.json()) as GenericResponse<ThingHistory[]>

  return json.data
}

export async function markViewed(ovenId: string) {
  const url = `${getEnvVar('OATS_API_URL')}/api/oven/${ovenId}/viewed`
  const resp = await fetch(url, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }
}

export async function setName(ovenId: string, name: string) {
  const url = `${getEnvVar('OATS_API_URL')}/api/oven/${ovenId}/name`
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
    body: JSON.stringify({ name }),
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json = (await resp.json()) as GenericResponse<null>

  return json.data
}

export async function setFavorite(ovenId: string, favorited: boolean) {
  const url = `${getEnvVar('OATS_API_URL')}/api/oven/favorites/${ovenId}`
  const resp = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
    body: JSON.stringify({ favorited }),
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json = (await resp.json()) as GenericResponse<null>

  return json.data
}

const queryOpts = {
  keepPreviousData: true,
  cacheTime: 1000 * 60 * 5,
  staleTime: 1000 * 60,
  refetchOnReconnect: false,
  refetchOnMount: false,
  refetchOnWindowFocus: false,
  retry: 0,
}

export function useNamedDevices() {
  return useQuery({
    queryKey: ['device-names'],
    queryFn: async () => {
      const favorites = await fetchFavorites(0, 1000, true)
      const ovenNames = favorites
        .map((item) => ({ [item.deviceID]: item.name }))
        .reduce((acc, item) => ({ ...acc, ...item }), {})

      return await {
        namedOvens: favorites,
        ovenNames,
      }
    },
    ...queryOpts,
  })
}

export function useFavorites() {
  return useQuery({
    queryKey: ['favorites'],
    queryFn: async () => {
      return fetchFavorites(0, 1000, false)
    },
    ...queryOpts,
  })
}

export function useOvenDetails(thingName: string) {
  return useQuery(
    // eslint-disable-next-line @tanstack/query/exhaustive-deps
    ['oven-details', thingName],
    async () => {
      return await fetchThing(getAuthToken(), thingName)
    },
    queryOpts,
  )
}

export const logLevelColors = {
  E: 'text-red-500',
  W: 'text-yellow-600',
  I: 'text-green-903',
  D: 'text-blue-600',
  V: 'text-gray-500',
  WEB: 'text-purple-600',
}

export type LogLevel = keyof typeof logLevelColors

export type RawLog = {
  timestamp: number
  message: string
}

export type LogEntry = {
  raw: string
  device: string
  timestamp: number
  hwTime: number
  level: LogLevel
  message: string
}

export type HistoricalLogEntry = {
  timestamp: number
  message: string
}

type LogResponse = {
  events: HistoricalLogEntry[]
}

export function parseLogEntry(entry: RawLog): LogEntry {
  const matches = entry.message.match(/(\w+) (\w) \((\d+)\) (.+)/) || []
  const [, device, level, hwTime, message] = matches

  return {
    raw: entry.message,
    device,
    timestamp: entry.timestamp,
    hwTime: parseInt(hwTime, 10),
    level: level as LogLevel,
    message,
  }
}

export function useDeviceLogs(
  thingName: string,
  startTimeMs: number,
  endTimeMs: number,
) {
  return useQuery<LogEntry[]>({
    queryKey: ['oven-logs', thingName, startTimeMs, endTimeMs],
    queryFn: async () => {
      const params = new URLSearchParams({
        start: startTimeMs.toString(),
        end: endTimeMs.toString(),
      })
      const paramsStr = params.toString()

      const authToken = getAuthToken()

      const url2 = `${getEnvVar('OATS_API_URL')}/api/oven/logs/${thingName}?${paramsStr}`
      const resp = await fetch(url2, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + authToken,
        },
      })

      if (!resp.ok) {
        console.error('Failed to fetch', resp.status, resp.statusText)
        throw new Error('Failed to fetch')
      }

      const json2 = (await resp.json()) as GenericResponse<LogResponse>

      let errorCount = 0
      const mapped = (json2.data?.events || []).map((event) => {
        const entry = parseLogEntry(event)

        if (entry.device !== thingName) {
          errorCount++
        }

        return entry
      })

      if (errorCount > 0) {
        console.warn(
          `Mismatched device names in logs (${errorCount} / ${mapped.length})`,
        )
      }

      return mapped
    },
    ...queryOpts,
  })
}

export async function fetchRoutineCookCyclesForHash(routineHash: string) {
  console.log('Fetching routine cook cycles for', routineHash)
  const url = `${getEnvVar('OATS_API_URL')}/api/oven/cookcycles?routineHash=${routineHash}`
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json = (await resp.json()) as GenericResponse<UnparsedOvenEvent[]>
  return json.data.map(convertUnparsedOvenEvent)
}

export async function fetchRoutineCookCyclesForDevice(deviceId: string) {
  const url = `${getEnvVar('OATS_API_URL')}/api/oven/cookcycles/${deviceId}`
  const resp = await fetch(url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + getAuthToken(),
    },
  })

  if (!resp.ok) {
    console.error('Failed to fetch', resp.status, resp.statusText)
    throw new Error('Failed to fetch')
  }

  const json = (await resp.json()) as GenericResponse<UnparsedOvenEvent[]>
  return json.data.map(convertUnparsedOvenEvent)
}

export function useRoutineHash(routineHash?: string) {
  return useQuery({
    queryKey: ['routine-cook-cycles', routineHash],
    queryFn: async () => {
      if (!routineHash) {
        console.error('No routine hash provided')
        throw new Error('No routine hash provided')
      }

      return await fetchRoutineCookCyclesForHash(routineHash)
    },
    ...queryOpts,
  })
}

export function useRoutines(deviceId?: string) {
  return useQuery({
    queryKey: ['routine-cook-cycles', deviceId],
    queryFn: async () => {
      if (!deviceId) {
        console.error('No device id provided')
        throw new Error('No device id provided')
      }

      return await fetchRoutineCookCyclesForDevice(deviceId)
    },
    ...queryOpts,
  })
}
