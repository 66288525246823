import { useQuery } from '@tanstack/react-query'
import { Modal, ModalHeader } from '@tovala/component-library'
import JSONTable from 'components/common/JSONTable'
import PaginationTableFooter from 'components/common/PaginationTableFooter'
import Table from 'components/common/Table'
import { useState } from 'react'
import {
  fetchInProgressJobs,
  fetchJobDetails,
  fetchJobHistory,
  JobData,
  JobProcessDetails,
  JobStatus,
  jobStatuses,
  JSONObject,
  getAuthToken,
} from 'utils/oatsApi'

const JobHistory = () => {
  const [inProgressPage, setInProgressPage] = useState(1)
  const [inProgressLimit, setInProgressLimit] = useState(10)

  const [historyPage, setHistoryPage] = useState(1)
  const [historyLimit, setHistoryLimit] = useState(10)
  const [historyStatus, setHistoryStatus] = useState<JobStatus>('COMPLETED')

  const [selectedJob, setSelectedJob] = useState<string | null>(null)

  const {
    data: inProgressJobs,
    isLoading: inProgressLoading,
    error: inProgressError,
    isError: isInProgressError,
    isPreviousData: isInProgressPreviousData,
  } = useQuery({
    queryKey: ['inProgressJobs', inProgressPage, inProgressLimit],
    queryFn: async () => {
      const bearerToken = getAuthToken()
      return await fetchInProgressJobs(
        bearerToken,
        inProgressPage,
        inProgressLimit,
      )
    },
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    retry: 0,
  })

  const {
    data: historyJobs,
    isLoading: historyLoading,
    error: historyError,
    isError: isHistoryError,
    isPreviousData: isHistoryPreviousData,
  } = useQuery({
    queryKey: ['historyJobs', historyPage, historyLimit, historyStatus],
    queryFn: async () => {
      const bearerToken = getAuthToken()
      return await fetchJobHistory(
        bearerToken,
        historyPage,
        historyLimit,
        historyStatus,
      )
    },
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    retry: 0,
  })

  const {
    data: jobDetails,
    isPreviousData: isJobDetailsPreviousData,
    // isLoading: jobDetailsLoading,
    // error: jobDetailsError,
    // isError: isJobDetailsError,
  } = useQuery({
    queryKey: ['jobDetails', selectedJob],
    queryFn: async () => {
      const bearerToken = getAuthToken()
      return await fetchJobDetails(bearerToken, selectedJob!)
    },
    enabled: !!selectedJob,
    keepPreviousData: true,
    cacheTime: 1000 * 60 * 5,
    staleTime: 1000 * 60,
    refetchOnReconnect: false,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchInterval: false,
    retry: 0,
  })

  return (
    <div>
      <div
        className={
          'relative overflow-x-hidden rounded-lg shadow-lg p-4 mb-4 max-w-[80vw] xl:max-h-screen sm:max-w-96 bg-slate-50'
        }
      >
        <h2 className="text-xl font-bold">In Progress Jobs</h2>
        <div>
          <Table
            columns={[
              {
                title: 'Job',
                key: 'job_id',
                width: 'w-80',
              },
              {
                title: 'Total Things',
                key: 'total_things',
                width: 'w-16',
              },
              {
                title: 'Status',
                key: 'status',
                width: 'w-28',
              },
              {
                title: 'Last Updated',
                key: 'last_updated',
                width: 'w-60',
              },
            ]}
            data={
              inProgressJobs?.map((job) => ({
                job_id: job.job_id,
                total_things: Object.keys(job.job_process_details).reduce(
                  (acc, key) =>
                    acc +
                    (typeof job.job_process_details[
                      key as keyof JobProcessDetails
                    ] === 'number'
                      ? job.job_process_details[key as keyof JobProcessDetails]
                      : 0),
                  0,
                ),
                status: job.status,
                last_updated: job.last_updated_at,
              })) ?? []
            }
            onRowClick={(_, row) => {
              setSelectedJob(row?.job_id as string | null)
            }}
          >
            <PaginationTableFooter
              isLoading={isInProgressPreviousData}
              onChangePage={setInProgressPage}
              onChangeRowsPerPage={setInProgressLimit}
              page={inProgressPage}
              rowsPerPage={inProgressLimit}
              total={inProgressJobs?.length || 0}
              totalPages={Math.ceil(
                (inProgressJobs?.length || 0) / inProgressLimit,
              )}
            >
              {inProgressLoading && (
                <div className="text-center text-gray-500 p-4 flex-1">
                  Loading...
                </div>
              )}

              {isInProgressError && (
                <div className="text-center text-red-500 p-4 flex-1">
                  Error:{' '}
                  {(inProgressError as Error)?.message ?? inProgressError}
                </div>
              )}

              {inProgressJobs?.length === 0 && (
                <div className="text-center text-gray-500 p-4 flex-1">
                  No results found
                </div>
              )}
            </PaginationTableFooter>
          </Table>
        </div>
      </div>
      <div
        className={
          'relative overflow-x-hidden rounded-lg shadow-lg p-4 mb-4 max-w-[80vw] xl:max-h-screen sm:max-w-96 bg-slate-50'
        }
      >
        <h2 className="text-xl font-bold">Job History</h2>
        <div>
          <Table
            columns={[
              {
                title: 'Job',
                key: 'job_id',
                width: 'w-80',
              },
              {
                title: 'Status',
                key: 'status',
                width: 'w-28',
                tooltip: true,
              },
              {
                title: 'Last Updated',
                key: 'last_updated',
                width: 'w-60',
              },
            ]}
            data={
              historyJobs?.map((job) => ({
                job_id: job.job_id,
                status: job.status,
                last_updated: job.last_updated_at,
              })) ?? []
            }
            onRowClick={(_, row) => {
              setSelectedJob(row?.job_id as string | null)
            }}
          >
            <PaginationTableFooter
              isLoading={isHistoryPreviousData}
              onChangePage={setHistoryPage}
              onChangeRowsPerPage={setHistoryLimit}
              page={historyPage}
              rowsPerPage={historyLimit}
              total={historyJobs?.length || 0}
              totalPages={
                Math.ceil((historyJobs?.length || 0) / historyLimit) || 0
              }
            >
              {historyLoading && (
                <div className="text-center text-gray-500 p-4 flex-1">
                  Loading...
                </div>
              )}

              {isHistoryError && (
                <div className="text-center text-red-500 p-4 flex-1">
                  Error: {(historyError as Error)?.message ?? historyError}
                </div>
              )}

              {historyJobs?.length === 0 && (
                <div className="text-center text-gray-500 p-4 flex-1">
                  No results found
                </div>
              )}
              <select
                className="border border-grey-3 rounded-lg px-2 py-1"
                onChange={(e) => setHistoryStatus(e.target.value as JobStatus)}
                value={historyStatus}
              >
                {jobStatuses.map((status) => (
                  <option key={status} value={status}>
                    {status}
                  </option>
                ))}
              </select>
            </PaginationTableFooter>
          </Table>
        </div>
      </div>
      {jobDetails && !isJobDetailsPreviousData && (
        <JobDetailsModal
          jobDetails={jobDetails}
          setSelectedJob={setSelectedJob}
        />
      )}
    </div>
  )
}

const JobDetailsModal = ({
  setSelectedJob,
  jobDetails,
}: {
  setSelectedJob: (jobId: string | null) => void
  jobDetails: JobData
}) => {
  return (
    <Modal
      onCloseModal={() => {
        setSelectedJob(null)
      }}
    >
      <ModalHeader
        onClickClose={() => {
          setSelectedJob(null)
        }}
      >
        <h1 className="text-xl font-bold">Job Details</h1>
      </ModalHeader>
      <div className="m-4">
        <div>
          <div>
            <JSONTable
              data={
                {
                  ...jobDetails,
                  job_process_details: {
                    ...jobDetails.job_process_details,
                    total_things: Object.keys(
                      jobDetails.job_process_details,
                    ).reduce(
                      (acc, key) =>
                        acc +
                        jobDetails.job_process_details[
                          key as keyof JobProcessDetails
                        ],
                      0,
                    ),
                  },
                } as JSONObject
              }
            />
          </div>
          <div className="flex justify-end mt-2">
            <button
              className="text-white px-4 py-2 rounded-lg font-bold bg-orange-1"
              onClick={() => {
                setSelectedJob(null)
              }}
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default JobHistory
