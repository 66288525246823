import { useRoutines } from 'utils/oatsApi'
import RenderedCookCycle from './RenderedCookCycle'

const CookEventsViewer = ({ ovenId }: { ovenId: string }) => {
  const { data, isLoading, isError } = useRoutines(ovenId)

  if (!data || isLoading || isError) {
    return (
      <div>
        <div>Placeholder</div>
      </div>
    )
  }

  return (
    <div>
      <h1 className="text-2xl font-bold my-4">Cook Events</h1>
      <div>
        {data.map((startEvent) => (
          <RenderedCookCycle
            key={startEvent.cookCycleID!}
            cookCycleId={startEvent.cookCycleID!}
            startEvent={startEvent}
          />
        ))}
      </div>
    </div>
  )
}

export default CookEventsViewer
