import { useEffect, useRef, useState } from 'react'
import { getEnvVar } from 'utils/env'
import { getAuthToken } from 'utils/oatsApi'

import { FixedSizeList as List } from 'react-window'
import useWebsocket from 'utils/socketConnection'

type FWEvent = {
  deviceID: string
  receiveTimeMs: number
  eventType: string
  eventKey: string
  eventTimeMs: number
  payload?: string
  ttl: number
}

function getColorForEvent(eventType: string, eventKey: string) {
  if (typeof eventType !== 'string') {
    return 'text-gray-500'
  }

  if (!eventType) {
    return 'text-gray-500'
  }

  if (eventType.toLowerCase() === 'web') {
    return 'text-purple-600'
  }

  if (typeof eventKey !== 'string') {
    return 'text-gray-500'
  }

  if (!eventKey) {
    return 'text-gray-500'
  }

  const lowerCaseEventKey = eventKey.toLowerCase()

  if (
    lowerCaseEventKey.includes('error') ||
    lowerCaseEventKey.includes('failed') ||
    lowerCaseEventKey.includes('fatal')
  ) {
    return 'text-red'
  }

  if (
    lowerCaseEventKey.includes('cancel') ||
    lowerCaseEventKey.includes('skip')
  ) {
    return 'text-yellow-600'
  }

  if (
    lowerCaseEventKey.includes('start') ||
    lowerCaseEventKey.includes('connected') ||
    lowerCaseEventKey.includes('complete') ||
    lowerCaseEventKey.includes('success')
  ) {
    return 'text-green'
  }

  if (lowerCaseEventKey.includes('progress')) {
    return 'text-blue-600'
  }

  if (
    lowerCaseEventKey.includes('request') ||
    lowerCaseEventKey.includes('set') ||
    lowerCaseEventKey.includes('change')
  ) {
    return 'text-fuchsia-600'
  }

  return 'text-gray-500'
}

function parseEvent(event: string): FWEvent[] {
  try {
    const parsed = JSON.parse(event)
    if (Object.keys(parsed).length !== 6 && Object.keys(parsed).length !== 7) {
      throw new Error('Invalid event')
    }

    if (typeof parsed.deviceID !== 'string') {
      throw new Error('Invalid deviceID')
    }

    if (typeof parsed.receiveTimeMs !== 'number') {
      throw new Error('Invalid receiveTimeMs')
    }

    if (typeof parsed.eventType !== 'string') {
      throw new Error('Invalid eventType')
    }

    if (typeof parsed.eventKey !== 'string') {
      throw new Error('Invalid eventKey')
    }

    if (typeof parsed.eventTimeMs !== 'number') {
      throw new Error('Invalid eventTimeMs')
    }

    if (typeof parsed.ttl !== 'number') {
      throw new Error('Invalid ttl')
    }

    return [parsed]
  } catch {
    return []
  }
}

const OvenEvents = ({ ovenId }: { ovenId: string }) => {
  const { data: events, setData: setEvents } = useWebsocket<FWEvent>(
    `${getEnvVar('OATS_API_URL')}/api/oven/events/${ovenId}/stream?token=${getAuthToken()}`,
    parseEvent,
    {
      onConnectData: () => ({
        deviceID: 'system',
        receiveTimeMs: Date.now(),
        eventType: 'WEB',
        eventKey: 'connected',
        eventTimeMs: Date.now(),
        ttl: 0,
      }),
      onDisconnectData: () => ({
        deviceID: 'system',
        receiveTimeMs: Date.now(),
        eventType: 'WEB',
        eventKey: 'disconnected',
        eventTimeMs: Date.now(),
        ttl: 0,
      }),
    },
  )
  const [autoScroll, setAutoScroll] = useState(true)

  const listRef = useRef<List>(null)

  useEffect(() => {
    function scrollToBottom() {
      if (!listRef.current) {
        return
      }

      if (events.length === 0) {
        return
      }

      if (!autoScroll) {
        return
      }

      listRef.current?.scrollToItem(events.length)
    }
    scrollToBottom()
  }, [events, autoScroll])

  return (
    <div className="flex flex-col space-y-4 w-full bg-slate-50 rounded-lg shadow-lg">
      <div className="relative overflow-hidden m-2">
        <List
          ref={listRef}
          // Prevents the list from jumping around when new logs are added that need a scrollbar
          // eslint-disable-next-line react/forbid-component-props
          className="overflow-scroll"
          height={400}
          itemCount={events.length}
          itemSize={35}
          width={'100%'}
        >
          {({ index, style }) => (
            <div className="text-nowrap" style={style}>
              <span className="text-gray-400 font-mono">
                {new Date(events[index].eventTimeMs).toLocaleString()}{' '}
              </span>
              <span
                className={`${getColorForEvent(
                  events[index].eventType,
                  events[index].eventKey,
                )} font-mono`}
              >
                {events[index].eventType} {events[index].eventKey}
              </span>
              {events[index].payload && (
                <span className="text-gray-400 ml-2">
                  {events[index].payload}
                </span>
              )}
            </div>
          )}
        </List>
        <div className="absolute bottom-4 right-6">
          <button
            className="bg-orange-1 text-white rounded-lg p-2 w-20 h-10 flex justify-center items-center"
            onClick={() => {
              setAutoScroll(!autoScroll)
            }}
          >
            {autoScroll ? 'Unlock' : 'Lock'}
          </button>
        </div>

        <div className="absolute top-0 right-6 w-36">
          <div
            className="flex flex-row text-gray-400 cursor-pointer"
            onClick={() => {
              setEvents([])
            }}
            title="Clear events"
          >
            <div className="mr-auto">Total events:</div>
            <div className="font-mono">{events.length}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OvenEvents
